*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* colors styling  */
:root {
  --primary-color: #3356c7;
  --primary-background-color: #edf2fe;
  --primary-hover-background-color: #e1e9ff;
  --secondary-color: #e5484d;
  --secondary-background-color: #fff7f7;
  --secondary-hover-background-color: #ffdbdc;
  --approve-color: #46a758;
  --approve-background-color: #f5fbf5;
  --approve-hover-background-color: #e9f6e9;
}

body {
  margin: 0;
  font-family: Inter, sans-serif, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background-color: rgb(248, 245, 245);
}
::-webkit-scrollbar-thumb {
  background-color: rgb(168, 167, 167);
  border-radius: 4px;
}
.bg-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
