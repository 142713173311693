.bg-container {
  min-height: 100dvh;
  position: relative;
}

.show-in-gray {
  filter: grayscale(100%);
}

.main-container {
  width: 100%;
  max-width: 1920px;
  margin-top: 60px;
}

@media (min-width: 992px) {
  .main-container {
    margin-top: 76px;
  }
}
